
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'Detail',
  components: {},
  props: {
    imgs: {
      type: Array,
      default: []
    },
    audioUrl: {
      type: String,
      default: () => ''
    },
    videoUrl: {
      type: String,
      default: () => ''
    },
    poster: {
      type: String,
      default: () => ''
    },
    text: {
      type: String,
      default: () => ''
    },
    courseserialName : {
      type: String,
      default: () => ''
    },
    courseserialId: {
      type: Number,
      default: -1
    }
  },
  setup(){
    const router = useRouter()
    const handlDetail = (id: number) => {
      router.push('/coursedetail/' + id)
    }
    return {
      handlDetail
    }
  }
})
